/*
 * Copyright 2023; Réal Demers.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {type AuthTokens, fetchAuthSession, signOut} from "aws-amplify/auth";

class AuthentificationService {
    public async isLoggedIn()  {
        return (await this.getAuthToken()) !== undefined
    }
    public async getAuthToken(): Promise<AuthTokens | undefined> {
        try {
            return (await fetchAuthSession()).tokens;
        } catch (err) {
            console.log(err);
        }
    }

    public async doLogout() {
        await signOut()
    }
}

// Singleton.
const authentificationService: AuthentificationService = new AuthentificationService();
export default authentificationService;


